import { container } from 'inversify-props';
import { AsyncContainerModule } from 'inversify';

import StorageService, { StorageServiceS } from '@/modules/common/services/storage.service';
import EventsApiService, { EventsApiServiceS } from '@/modules/events/events-api.service';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesApiService, { RatesApiServiceS } from '@/modules/rates/rates-api.service';
import UserApiService, { UserApiServiceS } from '@/modules/user/user-api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import CustomNotificationApiService, { CustomNotificationApiServiceS }
    from '@/modules/common/modules/custom-notification/custom-notification-api.service';
import TokenSessionStorageService, { TokenSessionStorageServiceS } from '@/modules/auth/token-session-storage.service';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import UserNavigationService, { UserNavigationServiceS } from '@/modules/user/user-navigation.service';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import ZendeskService, { ZendeskServiceS } from '@/modules/zendesk-chat/zendesk.service';

import RatesFiltersMiddleware, { RatesFiltersMiddlewareS } from '@/router/middlewares/rates-filters.middleware';
import MarketsFiltersMiddleware, { MarketsFiltersMiddlewareS } from '@/router/middlewares/markets-filters.middleware';
import HomepageRedirectMiddleware, { HomepageRedirectMiddlewareS } from '@/router/middlewares/homepage-redirect.middleware';
import DeepAnalysisFiltersMiddleware, { DeepAnalysisFiltersMiddlewareS } from '@/router/middlewares/deep-analysis-filters.middleware';
import AuthMiddleware, { AuthMiddlewareS } from '@/router/middlewares/auth.middleware';
import LevelMiddleware, { LevelMiddlewareS } from '@/router/middlewares/level.middleware';
import OnboardingMiddleware, { OnboardingMiddlewareS } from '@/router/middlewares/onboarding.middleware';
import ViewAsChainGuard, { ViewAsChainGuardS } from '@/router/guards/view-as-chain.guard';
import ViewAsHotelGuard, { ViewAsHotelGuardS } from '@/router/guards/view-as-hotel.guard';
import CarGuard, { CarGuardS } from '@/router/guards/car.guard';
import HotelGuard, { HotelGuardS } from '@/router/guards/hotel.guard';
import ClusterOrChainGuard, { ClusterOrChainGuardS } from '@/router/guards/cluster-or-chaine.guard';
import HotelOrCarGuard, { HotelOrCarGuardS } from '@/router/guards/hotel-or-car.guard';
import ClusterGuard, { ClusterGuardS } from '@/router/guards/cluster.guard';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';

import BookingPriceService, { BookingPriceServiceS } from '@/modules/rates/march-demo/booking-price.service';
import HomeService, { HomeServiceS } from '@/modules/home/home.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesDemandMapService, { RatesDemandMapServiceS } from '@/modules/rates/march-demo/rates-demand-map.service';
import CompsetSettingsService, { CompsetSettingsServiceS } from '@/modules/compsets/compset-settings.service';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import MarketsHistoryService, { MarketsHistoryServiceS } from '@/modules/common/modules/markets-history/markets-history.service';
import MarketsHistoryApiService, { MarketsHistoryApiServiceS } from '@/modules/common/modules/markets-history/markets-history-api.service';
import RoomTypesApiService, { RoomTypesApiServiceS } from '@/modules/room-types/room-types-api.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import HotelsApiService, { HotelsApiServiceS } from '@/modules/hotels/hotels-api.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CompsetsApiService, { CompsetsApiServiceS } from '@/modules/compsets/compsets-api.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/deep-analysis/deep-analysis-cluster.service';
import DeepCompsetAnalysisCommonService, { DeepCompsetAnalysisCommonServiceS } from '@/modules/deep-analysis/deep-analysis-common.service';
import DeepCompsetAnalysisApiService, { DeepCompsetAnalysisApiServiceS } from '@/modules/deep-analysis/deep-analysis-api.service';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import MealTypesApiService, { MealTypesApiServiceS } from '@/modules/meal-types/meal-types-api.service';
import RankingService, { RankingServiceS } from '@/modules/ranking/ranking.service';
import RankingApiService, { RankingApiServiceS } from '@/modules/ranking/ranking-api.service';
import RankingCommonService, { RankingCommonServiceS } from '@/modules/common/modules/ranking/ranking-common.service';
import RankingHistoryService, { RankingHistoryServiceS } from '@/modules/common/modules/ranking-history/ranking-history.service';
import RankingHistoryApiService, { RankingHistoryApiServiceS } from '@/modules/common/modules/ranking-history/ranking-history-api.service';
import ExceptionHandler, { ExceptionHandlerS } from '@/modules/common/modules/exception-handler/exception-handler.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import NumberOfGuestsApiService, { NumberOfGuestsApiServiceS } from '@/modules/number-of-guests/number-of-guests-api.service';
import NumberOfGuestsService, { NumberOfGuestsServiceS } from '@/modules/number-of-guests/number-of-guests.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsAlertService, { CarsAlertServiceS } from '@/modules/cars/car.alert.service';
import CarsAlertApiService, { CarsAlertApiServiceS } from '@/modules/cars/car.alert-api.service';
import CarsFilterApiService, { CarsFilterApiServiceS } from '@/modules/cars/cars-filter-api.service';
import CarsApiService, { CarsApiServiceS } from '@/modules/cars/cars-api.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ParityApiService, { ParityApiServiceS } from '@/modules/cars/modules/parity/parity-api.service';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import LocationAvailabilityApiService, { LocationAvailabilityApiServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-api.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import LocationAvailabilityService, { LocationAvailabilityServiceS }
    from '@/modules/cars/modules/location-availability/location-availability.service';
import AlertsService, { AlertsServiceS } from '@/modules/alerts/alerts.service';
import CarsAlertsService, { CarsAlertsServiceS } from '@/modules/cars/alerts/cars-alerts.service';
import AlertsApiService, { AlertsApiServiceS } from '@/modules/alerts/alerts-api.service';
import CarsAlertsApiService, { CarsAlertsApiServiceS } from '@/modules/cars/alerts/cars-alerts-api.service';
import RoomsTypeManagerApiService, { RoomsTypeManagerApiServiceS } from '@/modules/rooms-type-manager/rooms-type-manager-api.service';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';
import OccupancyService, { OccupancyServiceS } from '@/modules/occupancy/occupancy.service';
import CarsPriceHistoryService, { CarsPriceHistoryServiceS } from '@/modules/cars/cars-price-history.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HeadService, { HeadServiceS } from '@/modules/common/services/head.service';

import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '@/modules/common/modules/rates-price-history/rates-price-history.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-all.service';
import RatesPriceHistoryApiService, {
    RatesPriceHistoryApiServiceS,
} from '@/modules/common/modules/rates-price-history/rates-price-history-api.service';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ProvidersApiService, { ProvidersApiServiceS } from '@/modules/providers/providers-api.service';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import CarsTableauService, { CarsTableauServiceS } from '@/modules/cars/modules/tableau/cars-tableau.service';
import ScoreService, { ScoreServiceS } from '@/modules/score/score.service';
import ScoreApiService, { ScoreApiServiceS } from '@/modules/score/score-api.service';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import HotelCatalogService, { HotelCatalogServiceS } from '@/modules/hotels/modules/hotel-catalog/hotel-catalog.service';
import HotelCatalogApiService, { HotelCatalogApiServiceS } from '@/modules/hotels/modules/hotel-catalog/hotel-catalog-api.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import CarsCategoryManagerApiService, { CarsCategoryManagerApiServiceS } from '@/modules/cars-category-manager/cars-category-manager-api.service';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';

import ReportsService, { ReportsServiceS } from '@/modules/scheduled-reports/reports.service';
import ReportsApiService, { ReportsApiServiceS } from '@/modules/scheduled-reports/reports-api.service';

import DownloadExcelDirectlyMiddleware, { DownloadExcelDirectlyMiddlewareS } from '@/router/middlewares/download-excel-directly.middleware';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippApiService, { SippApiServiceS } from '@/modules/cars/modules/sipp/sipp-api.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import FleetApiService, { FleetApiServiceS } from '@/modules/cars/modules/fleet/fleet-api.service';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetHistoryService, { FleetHistoryServiceS } from '@/modules/cars/modules/cars-price-history/fleet-history.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterApiService, { ClusterApiServiceS } from '@/modules/cluster/cluster-api.service';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '@/modules/di-lite/di-lite-all-channels.service';
import DiLiteMarketService, { DiLiteMarketServiceS } from '@/modules/di-lite/di-lite-market.service';
import DiLiteMarketApiService, { DiLiteMarketApiServiceS } from '@/modules/di-lite/di-lite-market-api.service';
import CarsPriceHistoryApi, { CarsPriceHistoryApiS } from '@/modules/cars/cars-price-history-api.service';
import WalkmeService, { WalkmeServiceS } from '@/modules/walkme/walkme.service';
import WhatfixService, { WhatfixServiceS } from '@/modules/whatfix/whatfix.service';
import MarketApiService, { MarketApiServiceS } from '@/modules/market/market-api.service';
import MarketService, { MarketServiceS } from '@/modules/market/market.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import store from './store/index';
import { StoreS } from './store/types';
import ChainGuard, { ChainGuardS } from './router/guards/chain.guard';
import CacheService, { CacheServiceS } from './modules/common/services/cache/cache.service';
import ClusterScoresService, { ClusterScoresServiceS } from './modules/cluster/modules/score/score.service';
import ClusterScoreApiService, { ClusterScoreApiServiceS } from './modules/cluster/modules/score/score-api.service';
import ChainRatesService, { ChainRatesServiceS } from './modules/chain/modules/rates/chain-rates.service';
import ChainApiService, { ChainApiServiceS } from './modules/chain/chain-api.service';
import ChainMarketsService, { ChainMarketsServiceS } from './modules/chain/modules/markets/chain-markets.service';
import ChainService, { ChainServiceS } from './modules/chain/chain.service';

import PromotionsService, { PromotionsServiceS } from './modules/promotions/promotions.service';
import PromotionsApiService, { PromotionsApiServiceS } from './modules/promotions/promotions-api.service';
import PromotionsHistoryService, { PromotionsHistoryServiceS } from './modules/promotions/promotion-history.service';
import CarsAnalysisFiltersService, { CarsAnalysisFiltersServiceS } from './modules/cars/cars-analysis-filters.service';
import CarsAnalysisService, { CarsAnalysisServiceS } from './modules/cars/cars-analysis.service';
import CarsRateAlertApiService, { CarsRateAlertApiServiceS } from './modules/cars/car.rate-alert-api.service';
import CarsRateAlertService, { CarsRateAlertServiceS } from './modules/cars/car.rate-alert.service';
import CarsExcelReportService, { CarsExcelReportServiceS } from './modules/cars/cars-excel-report.service';
import CarsExcelReportApiService, { CarsExcelReportApiServiceS } from './modules/cars/cars-excel-report-api.service';
import NotificationsApiService, { NotificationsApiServiceS } from './modules/cars/modules/car-notifications/notifications-api.service';
import NotificationsService, { NotificationsServiceS } from './modules/cars/modules/car-notifications/notifications.service';
import CarsCompsetsAlertApiService, { CarsCompsetsAlertApiServiceS } from './modules/cars/car.compsets-alert-api.service';
import CarsCompsetsAlertService, { CarsCompsetsAlertServiceS } from './modules/cars/car.compsets-alert.service';
import CarsScanApiService, { CarsScanApiServiceS } from './modules/cars/cars-scan-api.service';
import CarsCompetitorsAlertService, { CarsCompetitorsAlertServiceS } from './modules/cars/car.competitors-alert.service';
import CarsBrandAlertService, { CarsBrandAlertServiceS } from './modules/cars/car.brand-alert.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from './modules/open-telemetry/open-telemetry.service';
import CarOpenTelemetryService, { CarOpenTelemetryServiceS } from './modules/cars/modules/open-telemetry/car.open-telemetry.service';

export function bootstrapContainer() {
    container.bind(StoreS).toConstantValue(store);
    container.addSingleton(ApiService, ApiServiceS);
    container.addSingleton(SocketService, SocketServiceS);
    container.addSingleton(NotifyService, NotifyServiceS);
    container.addSingleton(CustomNotificationService, CustomNotificationServiceS);
    container.addSingleton(CustomNotificationApiService, CustomNotificationApiServiceS);
    container.addSingleton(ConfigService, ConfigServiceS);
    container.addSingleton(ExceptionHandler, ExceptionHandlerS);
    container.addSingleton(HelperService, HelperServiceS);
    container.addSingleton(ValidatorService, ValidatorServiceS);
    container.addSingleton(HeadService, HeadServiceS);
    container.addSingleton(CacheService, CacheServiceS);

    container.addSingleton(RatesFiltersMiddleware, RatesFiltersMiddlewareS);
    container.addSingleton(MarketsFiltersMiddleware, MarketsFiltersMiddlewareS);
    container.addSingleton(DeepAnalysisFiltersMiddleware, DeepAnalysisFiltersMiddlewareS);
    container.addSingleton(HomepageRedirectMiddleware, HomepageRedirectMiddlewareS);
    container.addSingleton(AuthMiddleware, AuthMiddlewareS);
    container.addSingleton(LevelMiddleware, LevelMiddlewareS);
    container.addSingleton(OnboardingMiddleware, OnboardingMiddlewareS);
    container.addSingleton(ViewAsChainGuard, ViewAsChainGuardS);
    container.addSingleton(ViewAsHotelGuard, ViewAsHotelGuardS);
    container.addSingleton(CarGuard, CarGuardS);
    container.addSingleton(HotelGuard, HotelGuardS);
    container.addSingleton(ClusterOrChainGuard, ClusterOrChainGuardS);
    container.addSingleton(HotelOrCarGuard, HotelOrCarGuardS);
    container.addSingleton(ClusterGuard, ClusterGuardS);
    container.addSingleton(FeaturesGuard, FeaturesGuardS);
    container.addSingleton(ChainGuard, ChainGuardS);

    container.addSingleton(StorageService, StorageServiceS);
    container.addSingleton(TokenSessionStorageService, TokenSessionStorageServiceS);

    container.addSingleton(UserService, UserServiceS);
    container.addSingleton(UserApiService, UserApiServiceS);

    container.addSingleton(UserNavigationService, UserNavigationServiceS);

    container.addSingleton(AuthService, AuthServiceS);

    container.addSingleton(DocumentFiltersService, DocumentFiltersServiceS);

    container.addSingleton(NumberOfGuestsService, NumberOfGuestsServiceS);
    container.addSingleton(NumberOfGuestsApiService, NumberOfGuestsApiServiceS);

    container.addSingleton(MealTypesService, MealTypesServiceS);
    container.addSingleton(MealTypesApiService, MealTypesApiServiceS);

    container.addSingleton(RatesApiService, RatesApiServiceS);
    container.addSingleton(RatesCommonService, RatesCommonServiceS);
    container.addSingleton(RatesService, RatesServiceS);
    container.addSingleton(RatesAnalysisService, RatesAnalysisServiceS);
    container.addSingleton(RatesAnalysisFiltersService, RatesAnalysisFiltersServiceS);
    container.addSingleton(RatesAllService, RatesAllServiceS);
    container.addSingleton(RatesFiltersService, RatesFiltersServiceS);

    container.addSingleton(RatesPriceHistoryService, RatesPriceHistoryServiceS);
    container.addSingleton(RatesPriceHistoryCommonService, RatesPriceHistoryCommonServiceS);
    container.addSingleton(RatesPriceHistoryAllService, RatesPriceHistoryAllServiceS);
    container.addSingleton(RatesPriceHistoryApiService, RatesPriceHistoryApiServiceS);

    container.addSingleton(MarketsApiService, MarketsApiServiceS);
    container.addSingleton(MarketsService, MarketsServiceS);
    container.addSingleton(MarketsFiltersService, MarketsFiltersServiceS);
    container.addSingleton(MarketsCommonService, MarketsCommonServiceS);
    container.addSingleton(MarketsHistoryApiService, MarketsHistoryApiServiceS);
    container.addSingleton(MarketsHistoryService, MarketsHistoryServiceS);
    container.addSingleton(MarketApiService, MarketApiServiceS);
    container.addSingleton(MarketService, MarketServiceS);

    container.addSingleton(CompsetsApiService, CompsetsApiServiceS);
    container.addSingleton(CompsetsService, CompsetsServiceS);

    container.addSingleton(DeepCompsetAnalysisCommonService, DeepCompsetAnalysisCommonServiceS);
    container.addSingleton(DeepCompsetAnalysisClusterService, DeepCompsetAnalysisClusterServiceS);
    container.addSingleton(DeepCompsetAnalysisService, DeepCompsetAnalysisServiceS);
    container.addSingleton(DeepCompsetAnalysisApiService, DeepCompsetAnalysisApiServiceS);

    container.addSingleton(RoomTypesApiService, RoomTypesApiServiceS);
    container.addSingleton(RoomTypesService, RoomTypesServiceS);

    container.addSingleton(HotelsApiService, HotelsApiServiceS);
    container.addSingleton(HotelsService, HotelsServiceS);

    container.addSingleton(RoomsTypeManagerApiService, RoomsTypeManagerApiServiceS);
    container.addSingleton(RoomsTypeManagerService, RoomsTypeManagerServiceS);

    container.addSingleton(RankingService, RankingServiceS);
    container.addSingleton(RankingApiService, RankingApiServiceS);
    container.addSingleton(RankingHistoryService, RankingHistoryServiceS);
    container.addSingleton(RankingHistoryApiService, RankingHistoryApiServiceS);
    container.addSingleton(RankingCommonService, RankingCommonServiceS);

    container.addSingleton(ProvidersService, ProvidersServiceS);
    container.addSingleton(ProvidersApiService, ProvidersApiServiceS);

    container.addSingleton(EventsFilterService, EventsFilterServiceS);
    container.addSingleton(EventsManagerService, EventsManagerServiceS);
    container.addSingleton(EventsApiService, EventsApiServiceS);

    container.addSingleton(StoreFacade, StoreFacadeS);
    container.addSingleton(ZendeskService, ZendeskServiceS);
    container.addSingleton(WalkmeService, WalkmeServiceS);
    container.addSingleton(WhatfixService, WhatfixServiceS);
    container.addSingleton(OpenTelemetryService, OpenTelemetryServiceS);

    container.addSingleton(ScoreService, ScoreServiceS);
    container.addSingleton(ScoreApiService, ScoreApiServiceS);

    container.addSingleton(HotelCatalogService, HotelCatalogServiceS);
    container.addSingleton(HotelCatalogApiService, HotelCatalogApiServiceS);

    container.addSingleton(ReportsService, ReportsServiceS);
    container.addSingleton(ReportsApiService, ReportsApiServiceS);

    container.addSingleton(DownloadExcelDirectlyMiddleware, DownloadExcelDirectlyMiddlewareS);

    container.addSingleton(UserSettingsService, UserSettingsS);
    container.addSingleton(BookingPriceService, BookingPriceServiceS);
    container.addSingleton(CompsetSettingsService, CompsetSettingsServiceS);
    container.addSingleton(RatesDemandMapService, RatesDemandMapServiceS);
    container.addSingleton(HotelHomepageService, HotelHomepageServiceS);
    container.addSingleton(OccupancyService, OccupancyServiceS);

    // Car
    container.addSingleton(CarsService, CarsServiceS);
    container.addSingleton(CarsApiService, CarsApiServiceS);

    container.addSingleton(CarsScanApiService, CarsScanApiServiceS);

    container.addSingleton(CarsAlertService, CarsAlertServiceS);
    container.addSingleton(CarsAlertApiService, CarsAlertApiServiceS);
    container.addSingleton(CarsRateAlertApiService, CarsRateAlertApiServiceS);
    container.addSingleton(CarsRateAlertService, CarsRateAlertServiceS);
    container.addSingleton(CarsCompsetsAlertApiService, CarsCompsetsAlertApiServiceS);
    container.addSingleton(CarsCompsetsAlertService, CarsCompsetsAlertServiceS);
    container.addSingleton(CarsCompetitorsAlertService, CarsCompetitorsAlertServiceS);
    container.addSingleton(CarsBrandAlertService, CarsBrandAlertServiceS);

    container.addSingleton(CarsCategoryManagerApiService, CarsCategoryManagerApiServiceS);
    container.addSingleton(CarsCategoryManagerService, CarsCategoryManagerServiceS);

    container.addSingleton(CarsFiltersService, CarsFiltersServiceS);
    container.addSingleton(CarsFilterApiService, CarsFilterApiServiceS);
    container.addSingleton(CarsPriceHistoryService, CarsPriceHistoryServiceS);
    container.addSingleton(CarsPriceHistoryApi, CarsPriceHistoryApiS);
    container.addSingleton(CarsSharedService, CarsSharedServiceS);

    container.addSingleton(CarsTableauService, CarsTableauServiceS);

    container.addSingleton(ParityApiService, ParityApiServiceS);
    container.addSingleton(ParityService, ParityServiceS);
    container.addSingleton(ParitySettingsService, ParitySettingsServiceS);
    container.addSingleton(ParityFiltersService, ParityFiltersServiceS);

    container.addSingleton(LocationAvailabilityApiService, LocationAvailabilityApiServiceS);
    container.addSingleton(LocationAvailabilityService, LocationAvailabilityServiceS);
    container.addSingleton(LocationAvailabilityFiltersService, LocationAvailabilityFiltersServiceS);

    container.addSingleton(AlertsService, AlertsServiceS);
    container.addSingleton(AlertsApiService, AlertsApiServiceS);

    container.addSingleton(CarsAlertsService, CarsAlertsServiceS);
    container.addSingleton(CarsAlertsApiService, CarsAlertsApiServiceS);

    container.addSingleton(NotificationsApiService, NotificationsApiServiceS);
    container.addSingleton(NotificationsService, NotificationsServiceS);

    container.addSingleton(HomeFiltersService, HomeFiltersServiceS);
    container.addSingleton(HomeService, HomeServiceS);

    container.addSingleton(ClusterRatesService, ClusterRatesServiceS);
    container.addSingleton(ClusterMarketsService, ClusterMarketsServiceS);
    container.addSingleton(ClusterRankingService, ClusterRankingServiceS);
    container.addSingleton(ClusterScoresService, ClusterScoresServiceS);
    container.addSingleton(ClusterScoreApiService, ClusterScoreApiServiceS);

    container.addSingleton(SippService, SippServiceS);
    container.addSingleton(SippApiService, SippApiServiceS);
    container.addSingleton(SippSettingsService, SippSettingsServiceS);
    container.addSingleton(FleetService, FleetServiceS);
    container.addSingleton(FleetApiService, FleetApiServiceS);
    container.addSingleton(FleetHistoryService, FleetHistoryServiceS);
    container.addSingleton(FleetFilterService, FleetFilterServiceS);
    container.addSingleton(CarsAnalysisFiltersService, CarsAnalysisFiltersServiceS);
    container.addSingleton(CarsAnalysisService, CarsAnalysisServiceS);

    container.addSingleton(CarsExcelReportService, CarsExcelReportServiceS);
    container.addSingleton(CarsExcelReportApiService, CarsExcelReportApiServiceS);
    container.addSingleton(CarOpenTelemetryService, CarOpenTelemetryServiceS);

    // cluster
    container.addSingleton(ClusterService, ClusterServiceS);
    container.addSingleton(ClusterCompsetsService, ClusterCompsetsServiceS);
    container.addSingleton(ClusterApiService, ClusterApiServiceS);

    // chain
    container.addSingleton(ChainService, ChainServiceS);
    container.addSingleton(ChainRatesService, ChainRatesServiceS);
    container.addSingleton(ChainMarketsService, ChainMarketsServiceS);
    container.addSingleton(ChainApiService, ChainApiServiceS);

    // di-light
    container.addSingleton(DiLiteAllChannelsService, DiLiteAllChannelsServiceS);
    container.addSingleton(DiLiteMarketService, DiLiteMarketServiceS);
    container.addSingleton(DiLiteMarketApiService, DiLiteMarketApiServiceS);

    // promotions
    container.addSingleton(PromotionsService, PromotionsServiceS);
    container.addSingleton(PromotionsHistoryService, PromotionsHistoryServiceS);
    container.addSingleton(PromotionsApiService, PromotionsApiServiceS);
}

export async function loadAsyncModules() {
    const configModule = new AsyncContainerModule(async () => {
        const configService = container.get<ConfigService>(ConfigServiceS);
        await configService.init();
        container.rebind(ConfigServiceS).toConstantValue(configService);
    });

    await container.loadAsync(configModule);

    const exceptionModule = new AsyncContainerModule(async () => {
        const exceptionService = container.get<ExceptionHandler>(ExceptionHandlerS);
        container.rebind(ExceptionHandlerS).toConstantValue(exceptionService);
    });

    await container.loadAsync(exceptionModule);

    // These modules are too important, and we can't build dynamic loading there - too expensive
    const documentFiltersModule = new AsyncContainerModule(async () => {
        const documentFiltersService = container.get<DocumentFiltersService>(DocumentFiltersServiceS);
        container.rebind(DocumentFiltersServiceS).toConstantValue(documentFiltersService);
    });

    await container.loadAsync(documentFiltersModule);
}
